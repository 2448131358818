.harga_coret{
    text-decoration: line-through;
    color: rgb(185, 24, 24);
}
.amr-border{
    border-radius: 10px;
}
.form-sampul{
    padding: 0px;
}
.form-sampul img{
    height: 230px;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}
.gambar-cat{
    position: relative;
    display: flex;
    justify-content: center;
}
.gambar-cat .img-cat{
    position: absolute;
    top: -118px;
    height: 170px;
    width: 170px;
    object-fit: cover;
    border-radius: 20px;
}

.keterangan{
    margin-top: 70px;
}

.keterangan .judul{
    font-family: 'Signika Negative', sans-serif;
    font-size: 22px;
    margin-bottom: 15px;
}

.keterangan .note{
    font-size: 15px;
    text-indent: 30px;
    color: #a4adb5;
    word-break: keep-all;
}

.step{
    position: relative;
    height: 35px;
}
.step::after{
    content: "";
    border-bottom: 0.1px solid white;
    background: red;
    position: absolute;
    bottom: -12px;
    width: 100%;
}
.step .box{
    border-radius: 6px;
    position: absolute;
    border: 3px solid blue;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(45deg);
}
.step .box .judul{
    position: absolute;
    transform: rotate(315deg);
    font-size: 20px;
    font-weight: bold;
}
.step .nama{
    font-size: 20px;
    left: 50px;
    position: absolute;
    top: 5px;
    font-family: 'Signika Negative', sans-serif;
}
.amr-card-content{
    margin-top: 30px;
    margin-bottom: 10px;
}
.out-of-stock{
    position: absolute;
    display: none;
    top: 35px;
    left: 45px;
}
.not-ready .out-of-stock{
    display: block;
    z-index: 1000;
    font-weight: bold;
    color: #df3333;
    cursor: not-allowed;
}
.not-ready .amr-item{
    opacity: 0.3;
    cursor: not-allowed;
}
.amr-item{
    background: rgb(50, 50, 62);
    height: 95px;
    border-radius: 10px;
    position: relative;         
    padding: 10px 12px;
    position: relative;
    overflow: hidden; 
    transition: 0.2s;  
    cursor: pointer;
}
.amr-item.amr-selected::before {
    height: 40px;
    width: 40px;
}
.amr-item.amr-selected .nama{
    text-indent: 25px
}

.amr-item .nama{
    position: absolute;
    font-size: 13px;
    font-weight: 500;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: 'Blinker', sans-serif;
}
.amr-item .harga{
    position: absolute;
    bottom: 4px;
    font-size: 13px;
    font-family: 'Manrope', sans-serif;
}
.amr-item .img-srv{
    position: absolute;
    right: 15px;
    bottom: 13px;
}
.amr-item .img-srv img{
    height: 30px;
    height: 30px;
}

/*-----------------Card Payment---------------------*/
.card-payment{
    position: relative;
    background: rgb(50, 50, 62);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    cursor: pointer;
    height: 40px;
    transition: 1s ease-in;
}

.card-payment.tutup{
    height: 100%;
}
.card-payment.tutup .payment-content{
    visibility: visible;
    height: auto;
    padding: 4px 16px;
}
.payment-content{
    visibility:hidden;
    height: 0px;
    transition: 0.6s linear,visibility 0.01s;
}

.payment-content .payment-body.amr-selected{
    border-top-left-radius: 0px;
}
.payment-content .payment-body{
    height: 100px;
    background-color: rgb(229 231 235/1);
    border-radius: 10px;
    padding: 8px 20px;
    overflow: hidden;
    color: black;
    position: relative;
    transition: 0.2s;
}

.payment-body .form-img{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.payment-body .form-img::after{
    content: "";
    position: absolute;
    border-bottom: 1px solid rgb(27, 26, 26);
    width: 100%;
    left: 0px;
    margin-top: 22px;
    height: 100%;
}
.payment-body .form-img img{
    height: 30px;
    max-width: 80%;
    object-fit: contain;
}
.payment-body .note-payment{
    position: relative;
    height: 100%;
}
.payment-body .note-payment div:nth-child(1){
    position: absolute;
    bottom: 28px;
    font-family: 'Signika Negative', sans-serif;
    font-weight: bold;
}

.payment-body .note-payment div:nth-child(2){
    position: absolute;
    bottom: 28px;
    right: 0px;
    font-family: 'Signika Negative', sans-serif;
    font-weight: 600;
}

.card-payment .nama{
    font-weight: bold;
    padding: 8px 20px;
}

/*-----------------Card Payment Body---------------------*/
.card-payment-body{
    position: relative;
}

/*-----------------Card Payment List---------------------*/
.card-payment-list{
    position: relative;
    background-color: rgb(229 231 235/1);
    height: 40px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
}
.card-payment-list .all-img{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: calc(100% - 30px);
    overflow-x: visible;
    overflow-y: hidden;
    flex-direction: row;
}
.card-payment-body.tutup .card-payment-list .all-img{
    display: none;
}
.card-payment-list .all-img img{
    height: 35px;
    width: 50px;
    object-fit: contain;
    margin: 0 6px;
}
.card-payment-list .for-ico{
    cursor: pointer;
}
.card-payment-list .for-ico::after{
    content: "\f0d7";
    position: absolute;
    right: 10px;
    top: 5px;
    font-family: "Font Awesome 6 Free";
    font-size: 20px;
    color: black;
    font-weight: 800;
}
.card-payment-body.tutup .card-payment-list .for-ico::after{
    content: "\f0d8";
    position: absolute;
    right: 10px;
    top: 5px;
    font-family: "Font Awesome 6 Free";
    font-size: 20px;
}
/* ===============================================================*/


.content-konfirmasi .judul {
    margin-bottom: 8px;
}
.content-konfirmasi .judul::before {
    content: "";
    border-top: 1px solid #4f40cd;
    width: 20px;
    display: inline-block;
    position: relative;
    top: -5px;
    margin-right: 4px;
}

.content-konfirmasi .data {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.content-konfirmasi .data .kiri {
    width: 40%;
    font-family: 'Blinker', sans-serif;
}

.content-konfirmasi .data .kanan {
    width: 60%;
    text-align: end;
    margin-left: 10px;
    word-break: break-all;
    font-family: 'Manrope', sans-serif;
}

.amr-input i{
    position: absolute;
    top: 7px;
    font-size: 24px;
    color: blue;
    z-index: 6;
    left: 10px;
}

.amr-input input{
    text-indent: 35px;
    z-index: 1;
}

.form-note-target{
    position: relative;
    max-height: 200px;
}
.form-note-target img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
#modal-note .modal-content{
    background-color: transparent !important;
}
#modal-note .modal-body{
    padding: 0px 15px;
}
#modal-note .modal-footer{
    border-top: 0.5px solid rgb(68 62 62) !important;
}
.note-target-scroll {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .note-target-scroll::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }
@media (max-width : 768px){
    .form-sampul img{
        height: 150px;
        filter: brightness(0.8);
        opacity: 0.8;
    }
    .gambar-cat .img-cat{
        height: 120px;
        width: 120px;
        top: -95px;
    }
    .keterangan{
        margin-top: 40px;
    }
}