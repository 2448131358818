#membership .level-membership{
    background: rgb(50, 50, 62);
    height: 65px;
    border-radius: 10px;
    position: relative;         
    padding: 10px 12px;
    position: relative;
    overflow: hidden; 
    transition: 0.2s;  
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
#membership .level-membership .level{
    position: absolute;
    top: 10px;
    font-size: 16px;
    font-weight: 600;
}
#membership .level-membership .harga{
    right: 10px;
    position: absolute;
    bottom: 5px;
    font-size: 18px;
}
#membership .level-membership.amr-selected .level{
    text-indent: 50px;
}