.input-jumlah{
    display: flex;
    justify-content: space-between;
}
.input-jumlah span{
    display: flex;
    align-items: center;
    color: rgb(47, 51, 44);
    padding: 0px 10px;
    cursor: pointer;
    user-select: none;
    border-radius: 10px;
}
.input-jumlah input{
    border-radius: 0px;
    text-align: center;
}