@import url('https://fonts.googleapis.com/css2?family=Signika+Negative&family=Blinker:wght@600&family=Manrope:wght@400&display=swap');        
:root {
    --amr-padding-content: 4.4%;
    --colorTableBorder: 0.5px solid rgb(77, 71, 71);
    --amr-color-body : #272730;
    --amr-color-header: #272730;
    --amr-color-navbar: #272730;
    --amr-color-footer: rgb(50, 50, 62);
    /*--amr-color-navbar: #272730;*/
    /*--amr-color-footer: #272730;*/
}
.amr-font-1{
    font-family: 'Blinker', sans-serif;
}
.amr-font-2{
    font-family: 'Manrope', sans-serif;
}
.amr-font-3{
    font-family: 'Signika Negative', sans-serif;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.amr-scroll {
    scrollbar-width: auto;
    scrollbar-color: #5b575c #ffffff;
}

/* Chrome, Edge, and Safari */
.amr-scroll::-webkit-scrollbar {
    width: 11px;
    height: 5px;
}

.amr-scroll::-webkit-scrollbar-thumb {
    background-color: #5b575c;
    border-radius: 10px;
    border: 3px solid #735454;
}


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--amr-color-body) !important;
    color: white !important;
}

.bg-amr-root{
    background-color: var(--amr-color-body);
}

.text-amr-root{
    color: var(--amr-color-body);
}

.header{
    position: relative;
    width: 100%;
    height: 70px;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    transition: 1s;
    background-color: var(--amr-color-header);
}
.header > .header-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: var(--amr-padding-content);
    height: 100%;
    flex: 2;
    /* padding: 0px 5%; */
}

.header > .header-icon img{
    height: 70px;
    max-width: 200px;
    cursor: pointer;
}
.header > .logo-user{
    display: flex;
    align-items: center;
    padding-right: var(--amr-padding-content);
}

.header.down{
    display: none;
    visibility: hidden;
}

.header.up{
    display: flex;
    visibility: visible;
}

.header.down + .amr-navbar{
    position: fixed; /* Ini Fixed */
    width: 100%;
}

.amr-navbar{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 30px var(--amr-padding-content);
    box-shadow: 0px 13px 12px 0px rgb(80 74 74);
    justify-content: space-between;
    z-index: 10;
    transition: 1s;
    background-color: var(--amr-color-navbar);
}

.amr-navbar .navbar-ico{
    display: flex;
    align-items: center;
    justify-content: center;
}

.amr-navbar .navbar-ico > img{
    height: 65px;
    max-width: 200px;
    cursor: pointer;
}

.amr-navbar .user-ico , .amr-navbar .search-ico{
    margin-left: 15px;
    overflow: hidden;
}
.amr-navbar .ico-user{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    background: linear-gradient(to right, #136a8a, #267871);
} 

.menu-item{
    display: flex;
    flex: 2;
    overflow: hidden;
    flex-wrap: nowrap;
}
.menu-item #for-close-hamburger{
    height: 60px;
    position: relative;
    display: none;
}
.menu-item #for-close-hamburger .close{
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 6px;
    font-weight: bold;
}
.amr-navbar .menu-item a{
    flex: 0 0 auto;
    display: flex;
    margin-right: 30px;
    color: inherit;
    text-decoration: none;
    font-size: 15px;
    align-items: center;
    transition: 0.2s;
}

.amr-navbar .tambahan-nav{
    cursor: pointer;
    padding-right: var(--amr-padding-content);
}

#hamburger-menu {
    display: none;
    cursor: pointer;
}

.amr-navbar .menu-item a i{
    font-size: 13px;
}
.amr-navbar .menu-item a span{
    margin-left: 8px;
    transition: 0.3s linear;
}

.amr-content{
    padding-top: 0px;
    padding-left: var(--amr-padding-content);
    padding-right: var(--amr-padding-content);
    overflow: hidden;
    min-height: 100vh;
}
#amr-header.down ~ .amr-content{
    padding-top: 130px;
}
/* Dekstop */
@media (min-width : 768px){
    .amr-navbar .navbar-ico{
        display: none;
    }

    .amr-navbar .search-ico{
        display: none;
    }
    
}

/* Mobile */
@media (max-width : 1025px){
    .header{
        display: none!important;
    }
    .menu-item #for-close-hamburger{
        display: block;
    }
    #hamburger-menu {
        font-size: 25px;
        display: flex;
        color: #dadee3;
    }
    #amr-header.down ~ .amr-content{
        padding-top: 60px;
    }
    .amr-navbar .menu-item{
        display: block;
        position: fixed;
        top: 0;
        left: -100%;
        background-color: rgb(39 39 48/1);
        width: 15rem;
        height: 100vh;
        transition: 0.5s;
    }
    .amr-navbar .menu-item a:nth-child(1){
        margin-top: 10px;
    }
    .amr-navbar .menu-item a{
        padding: 15px 0;
        margin-left: 20px;
        margin-right: 0px;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;
    }
    .amr-navbar .menu-item a i{
        font-size: 16px;
    }
    .amr-navbar .menu-item a span{
        margin-left: 12px;
        transition: 0.3s linear;
    }
    .amr-navbar.active .menu-item{
        left: 0;
    }
    .navbar-ico{
        flex: 2;
    }
    .amr-navbar .search-ico{
        display: block;
    }
}

.amr-card{
    position: relative;
    box-shadow: 1px 5px 15px 5px hsl(0deg 0% 77% / 13%);
    padding: 15px;
    border-radius: 10px;
    margin: 10px 0px;
}

.amr-card2{
    position: relative;
    background: linear-gradient(163.42deg,#374353c3 -107%,#ffffff00 105.46%);
    border: 1px solid #626274;
    margin: 10px 0px;
    border-radius: 10px;
    padding: 15px;
}

.amr-footer{
    position: relative;
    bottom: 0px;
    margin-top: 60px!important;
    background-color: var(--amr-color-footer);
    padding-top: 30px;
    padding-left: var(--amr-padding-content);
    padding-right: var(--amr-padding-content);
}

.amr-footer .form-img-footer{
    width: 100%;
}

.amr-footer .form-img-footer img{
    height: 110px;
    width: 270px;
    object-fit: contain;
}

.amr-footer .form-img-footer .keterangan{
    word-break: all;
    margin-top: 8px;
    font-family: 'Signika Negative', sans-serif;
}

.amr-footer .form-other .induk{
    display: flex;
    flex-direction: column;
    font-family: 'Signika Negative', sans-serif;
}
.amr-footer .form-other .induk .judul{
    font-weight: bold;
    font-size: 16px;
}
.amr-footer .form-other .induk a{
    color: white;
    text-decoration:none;
    font-weight: 400;
    padding: 6px 0px;
    font-size: 15px;
}
.amr-footer .form-other .for-ico{
    display: flex;
    gap: 10px;
}
.amr-footer .form-other .for-ico a{
    font-size: 23px;
    color: white;
}
.amr-not-allow{
    cursor: not-allowed!important;
}
.amr-btn{
    display: flex;
    align-items: center;
    color: white!important;
    background: linear-gradient(229deg,#4e19ef -1.95%,#114075 97.95%)!important
}

.amr-btn-danger {
    color: white!important;
    background: linear-gradient(229deg,#e14545 -1.95%,#546993 97.95%)!important;
}

.amr-selected{
    border: 3px solid #144b97;
}
.amr-selected::before{
    font-family: "Font Awesome 6 Free";
    content: "\f00c";
    position: absolute;
    top: -5px;
    left: -5px;
    width: 60px;
    height: 45px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    background-color: #144b97;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 50px;
}
#toast-container{
    margin-top: 8px;
}
.toast{
    opacity: 1 !important;
}
.modal.show{
    background-color: rgba(75,85,99,.75);
}
.amr-loader{
    animation: animation_loader 1.5s 0s infinite;
    font-size: 25px;
}
.hideifOver{
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}
.hideifOver:hover{
    overflow: visible;
    text-overflow: unset;
}
@keyframes animation_loader {
    0%{
        transform: rotate(90deg);
    }   
    100%{
        transform: rotate(720deg);
    }
}


.amr-form{
    position: relative;
    margin-bottom: 12px;
}
.amr-form textarea:focus-visible{
    outline: 0px;
}
.amr-form select,.amr-form textarea{
    padding: 17px 20px;
    background: rgb(50, 50, 62)!important;
    border: 1px solid #626274;
    border-radius: 15px;
    color: rgb(217 205 205)!important;
    width: 100%;
    font-family: 'Blinker', sans-serif;
}
.amr-form .amr-input{
    background: rgb(50, 50, 62);
    color: rgb(217 205 205);
    border: 1px solid #626274;
    border-radius: 15px;
    padding: 17px 20px;
    padding-bottom: 10px;
    width: 100%;
    font-weight: bold;
    z-index: 1;
}
.amr-form input[type="number"]::-webkit-outer-spin-button,
.amr-form input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.amr-form .amr-input[disabled]{
    cursor: not-allowed;
    background: #484242;
}
.amr-form .amr-input[disabled] ~ .amr-label , .amr-form .amr-input:focus ~ .amr-label , .amr-input:valid ~ .amr-label{
    top: 0px;
    left: 35px;
    font-size: 14px;
    color: #8a96a0!important;
}

.amr-form .amr-label{
    position: absolute;
    left: 30px;
    bottom: 18px;
    transition: 0.5s;
    z-index: 0;
    font-family: 'Blinker', sans-serif;
}
.amr-form .amr-ico-pass{
    position: absolute;
    right: 30px;
    bottom: 18px;
    transition: 1s;
}
.amr-form .amr-input:focus-visible{
    outline: 0px;
}

.amr-hr{
    position: relative;
    width: 100%;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex: 1;
}
.amr-hr::before , .amr-hr::after{
    content: "";
    position: relative;
    bottom: 10px;
    border-bottom: 1px solid white;
    flex: 1;
}
.amr-hr::before{
    margin-right: 10px;
}
.amr-hr::after{
    margin-left: 10px;
}

.pagination li a{
    background: rgb(50, 50, 62)!important;
    color: white;
}
.pagination li:not(.disabled) a{
    font-weight: 600;
    font-family: 'Blinker', sans-serif;
}
.pagination li.active a{
    background: #0d6efd!important;
    color: white;
}
.dropdown-menu.show{
    background: rgb(50, 50, 62)!important;
    position: absolute;
    right: 30px;
    top: calc(100% + 10px);
    max-width: 220px;
}
.dropdown-menu.show a{
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
}
.dropdown-menu.show .dropdown-item:focus, .dropdown-menu.show .dropdown-item:hover{
    background: linear-gradient(to right, #360033, #0b8793);
}


table thead{
    border: 0px;
}

table tbody{
    border: 0px;
}
table tr th , table tr td {
    color: white;
    border: 0px;
    border-bottom: var(--colorTableBorder);
}
table tbody tr td:not(:last-child),table thead tr th:not(:last-child){
    border-right: var(--colorTableBorder);
}
table tbody tr td{
    border-bottom: var(--colorTableBorder);
}
.last{
    width: 1px;
    white-space: nowrap;
    flex-wrap: wrap;
}
.amr-preloader{
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: none;
    position: fixed;
}
  .amr-preloader{
    display: flex;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amr-preloader .progres{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amr-preloader .progres::after{
    content: "";
    display: block;
    position: absolute;
    height: 250px;
    width: 250px;
    border-radius: 50%;
    border: 10px solid #2196f3;
    border-top-color: transparent;
    border-left-color: transparent;
    animation: spin 2s alternate infinite;
  }
  .img-preloader{
        height: 180px;
        width: 180px;
        object-fit: contain;
  }
  @keyframes spin {
    0% { 
      transform: rotate(0deg); 
    }
    20%{
      border: 20px solid #ff0040;
      border-top-color: transparent;
      border-left-color: transparent;
    }
    40%{
      border: 30px solid #D2F488;
      border-top-color: transparent;
      border-left-color: transparent;
    }
    100% { 
        border: 10px solid #D2F488;
        transform: rotate(360deg); 
    }
  }