.swiper{
    overflow: visible!important;
}
.content-swipper{
    position: relative;
    margin-top: 20px;
    height: 350px;
}
.amr-img-swipper{
    cursor: pointer;
    width: 100%;
    transition: 0.8s;
    border-radius: 15px;
}

.swiper-slide-prev{
    left: 90%;
}
.swiper-slide-next{
    right: 90%;
}
.swiper-slide:not(.swiper-slide-active){
    margin-top: auto;
    margin-bottom: auto;
    z-index: -1;
}
.swiper-slide:not(.swiper-slide-active) .amr-img-swipper{
    position: absolute;
    top: -90px;
    height: 200px;
}

.swiper-slide-active .amr-img-swipper:hover{
    transform: scale(1.1);
}
.swiper-slide-active .amr-img-swipper{
    height: 300px;
    z-index: 100;    
}

@media (max-width : 768px){
    .content-swipper{
        height: 200px;
    }
    .swiper-slide-active .amr-img-swipper{
        max-width: 100%;
        height: 150px;
    }
    .swiper-slide:not(.swiper-slide-active) .amr-img-swipper{
        height: 150px;
    }
    .swiper{
        overflow: hidden!important;
    }
    .swiper-slide{
        overflow: hidden!important;
    }
    .amr-img-swipper{
        transition:none;
    }
}