#profile .amr-card2.static{
    height: 200px;
}
#profile .ico-user{
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
    background: linear-gradient(to right, #136a8a, #267871);
}

#profile .pesanan{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 0 30px;
    margin-top: 10px;
}

#profile .pesanan .item{
    display: flex;
    flex-direction: column;
}

#profile .pesanan .item .jumlah{
    font-size: 40px;
}
#profile .pesanan .item .status{
    font-size: 18px;
}
#profile .amr-card2.upgrade-membership{
    background: linear-gradient(to right, #780206, #061161);
}

#profile .amr-card2.upgrade-membership .for-membership{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 0 25px;
}
#profile .amr-card2.upgrade-membership .for-membership span{
    font-size: 16px;
}

#profile .btn-upgrade{
    animation: btnUp 0.5s infinite;
}

@keyframes btnUp {
    0%{
        box-shadow: 0 0 5px 5px #157d85;
    }
    25%{
        box-shadow: 0 0 20px 0px #157d85;
    }
    100%{
        box-shadow: 0 0 5px 5px #157d85;
    }
}

@media (max-width : 1025px){
    #profile .pesanan{
        justify-content: unset;
        align-items: unset;
        text-align: unset;
        padding: 0;
        flex-direction: column;
    }
    #profile .pesanan .item {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    #profile .pesanan .item .status{
        font-size: 18px;
    }
    #profile .pesanan .item .jumlah{
        font-size: 22px;
    }
}


/* INI UNTUK PROFILE EDIT */

#profile-edit .tab-content .fade{
    transition: 1.5s;
}
#profile-edit ul.nav-tabs{
    border: 0px;
}
#profile-edit .nav-item a{
    margin-right: 10px;
    border-radius: 5px;
    font-size: 15px;
    color: white;
    padding : 8px 15px;
}
#profile-edit .nav-item a[aria-selected="true"]{
    color: #18b1a4!important;
    background-color: white;
}

@media (max-width : 760px){
    #profile-edit .hideOnMobile{
        display: none;
    }
}