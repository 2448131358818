.qris{
    text-align: center;
    padding: 10px;
    background: white;
    border-radius: 10px;
    height: 250px;
}
.img-pay{
    position: relative;
    height: 400px;
    width: 100%;
    object-fit: contain;
    transition: 0.5s;
}
.img-pay:hover{
    transform: scale(1.5);
}
.amr-item-detail{
    margin-bottom: 6px;
}
.amr-kiri{
    font-size: 17px;
    margin-right: 10px;
}
.amr-kanan{
    font-size: 18px;
    font-weight: bold;
    word-break: break-all;
}

#pembelian{
    display: flex;
    justify-content: space-between;
}
#pembelian .title-history{
    display: flex;
    flex-direction: column;
    padding: 0px 8px;
}

.title-history div:nth-child(1){
    color: rgb(156 163 175/1);
    font-weight: bold;
    font-size: 18px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-history div:nth-child(2){
    font-weight: 700;
    font-size: 16px;
    word-break: break-all;
    text-align: center;
}
.remove-border{
    border: 0px;
}
.img-cat{
    height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 10px;
}
.name-cat{
    color: rgb(198 200 205);
    font-weight: bold;
    font-size: 18px;
}
.batas{
    position: relative;
    padding-bottom: 20px;
}
.batas::after{
    position: absolute;
    content: "";
    width: 150%;
    border-bottom: 1px solid #626274;
    height: 20px;
    left: -25px;
    bottom: 0px;
}

#detail_order .key{
    flex: 1;
    color: rgb(156 163 175/1);
    font-size: 16px;
}
#detail_order .val{
    flex: 1;
    font-size: 14px;
    font-weight: 600;
}
#total_bayar{
    padding-top: 10px;
}
#total_bayar div:nth-child(1){
    flex: 1;
    color: rgb(156 163 175/1);
    font-size: 18px;
}
#total_bayar div:nth-child(2){
    flex: 1;
    text-align: end;
    color: red;
    font-size: 20px;
}
.ico-copy{
    font-size: 18px;
    color: rgb(156 163 175/1)!important;
    cursor: pointer;
}

@media (max-width : 900px){
    #pembelian{
        display: flex;
        flex-direction: column;
    }

    #pembelian .title-history{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .title-history div:nth-child(1){
        display: inline;
        height: 100%;
        margin: 0px;
        width: 50%;
    }

    .title-history div:nth-child(2){
        width: 50%;
        text-align:left;
    }
}