#search .for-icon {
    position: absolute;
    left: 15px;
    top: 10px;
}
#search .amr-form .amr-label {
    position: absolute;
    left: 50px;
    bottom: 17px;
    transition: 0.5s;
    z-index: 0;
}

#search .amr-form .amr-input{
    padding-left: 50px;
}

#search .amr-form .amr-input:focus ~ .amr-label,#search .amr-input:valid ~ .amr-label{
    left: 50px;
}
#search .amr-form .amr-input:focus ~ .for-icon,#search .amr-input:valid ~ .for-icon{
    top: 10px;
}