@import url('https://fonts.googleapis.com/css2?family=Asap+Condensed&family=Baloo+2&family=Genos&family=Varela+Round&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #272730 !important;
  color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.amr-f-1{
  font-family: 'Asap Condensed', sans-serif;
}
.amr-f-2{
  font-family: 'Baloo 2', cursive;
}
.amr-f-3{
  font-family: 'Genos', sans-serif;
}
.amr-f-4{
  font-family: 'Varela Round', sans-serif;
}