#search-order .amr-card2{
    position: relative;
    padding: 0px;
    height: 120px;
}
#search-order .core{
    height: 100%;
    padding: 10px 0;
}
#search-order #for-gambar {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 15px;
}
#search-order #for-gambar img{
    height: 100px;
    width: 100px;
    border-radius: 5px;
}
#search-order #for-detail {
    flex: auto;
    margin-left: 10px;
    position: relative;
    overflow: hidden;
    word-break: break-all;
}
#search-order .hide1{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#search-order #for-detail .nama {
    font-size: 16px;
}
#search-order #for-detail .category {
    font-size: 14px;
}
#search-order #for-detail .pay {
    font-size: 14px;
}
#search-order #for-detail .tanggal-order {
    font-size: 14px;
    position: absolute;
    bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#search-order #for-status{
    flex: 2;
    font-size: 16px;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 5px;
    position: relative;
}
#search-order #for-status .status{
    position: absolute;
    bottom: 0px;
    right: 0px;
}
@media (max-width : 768px){
    #search-order #for-gambar img{
        height: 80px;
        width: 80px;
        align-items: baseline;
    }
}