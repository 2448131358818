@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');
.tab-content .fade{
    transition: 1.5s;
}
ul.flash-saleTabs{
    border: 0px;
}
li.nav-item{
    margin-right: 10px;
}
ul.flash-saleTabs .nav-item button{
    color: white!important;
    border-radius: 5px;
    font-size: 22px;
    font-weight: bold;
    color: #990909;
}
ul.flash-saleTabs .nav-item button[aria-selected="true"]{
    color: #990909!important;
}
ul.order-Tabs::-webkit-scrollbar{
    display: none;
}
ul.order-Tabs{
    display: flex;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    overflow: scroll;
    border: 0px;
}

ul.order-Tabs .nav-item button{
    color: white!important;
    font-size: 17px;
    font-weight: bold;
    background: transparent !important;
    border: 0px;
}
ul.order-Tabs .nav-item button[aria-selected="true"]{
    color: #990909!important;
    border-bottom: 2px solid rgb(139 56 56)
}
.amr-countdown{
    font-size: 22px;
    padding: 0 5px;
    background: #cbc6c6;
    color: #992323;
    margin-left: 10px;
    border-radius: 10px;
}
.amr-countdown.otw{
    color: #35a384;
}


.amr-g-flash{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    transition: 1s;
    scroll-behavior: smooth;
    user-select: none;
}

.core-flash{
    height: 200px;
    width: 15%;
    transition: 1s;
    flex: 0 0 auto;
    margin-right: 10px;
}
.flash{
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 15px;
    transition: 0.6s linear;
    cursor: pointer;
}
.flash .layanan{
    font-size: 14px;
    font-weight: bold;
    word-break: break-all;
    white-space: nowrap;
}
.flash .keterangan{
    position: absolute;
    bottom: 0px;
    padding: 6px;
    width: 100%;
}
.flash .keterangan .for-layanan{
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.flash .harga-flash .form-asli{
    overflow: hidden;
}
.flash .harga-flash .asli{
    background-color: rgb(9, 241, 211);
    border-radius: 10px;
    padding: 2px 5px;
    color: rgb(48, 138, 100);
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
}
.flash .harga-flash .coret{
    background-color: rgb(255, 9, 9);
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 11px;
    text-decoration: line-through;
    color: rgb(250, 158, 158);
    font-weight: bold;
    font-style: italic;
    white-space: nowrap;
}

.img-flash{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.amr-g{
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    gap: 1.5rem;
    padding: 10px 0px;
}

.judul-layanan{
    font-family: 'Prompt', sans-serif;
    font-size: 26px;
    width: 100%;
}

.core-layanan{
    height: 220px;
    width: 100%;
}

.layanan{
    height: 100%;
    width: 100%;
    border-radius: 15px;
    transition: 0.6s linear;
    cursor: pointer;
    overflow: hidden;
}

.layanan:hover{
    background: #16222A;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.layanan:hover .img-layanan{
    display: none;
}
.layanan .img-layanan{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.layanan:hover .detail-layanan {
    display: flex;
    position: relative;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
}
.detail-layanan {
    position: relative;
    top: 100%;
    transition: 0.5s;
}
.layanan .detail-layanan .img-detail{
    padding-top: 20%;
    width: 40%;
    height: 40%;
}

.detail-layanan .keterangan{
    position: absolute;
    display: flex;
    bottom: 10px;
    padding: 0px 10px;
    flex-direction: column;
    width: 100%;
}

.keterangan .nama{
    font-size: 16px;
    font-weight: bold;
    color:white;
}

.keterangan .dev{
    font-size: 16px;
    font-weight: bold;
    color:white;
}

@media (max-width : 768px){
    .core-layanan{
        height: 190px;
    }

    .amr-g{
        gap: 0.5rem;
        grid-template-columns: repeat(3,minmax(0,1fr));
    }
    .core-flash {
        width: 33.3%;
    }
    .keterangan .nama{
        font-size: 15px;
    }
    .keterangan .dev{
        font-size: 15px;
    }
}

@media (min-width : 768px) and (max-width: 1000px){
    .amr-g{
        gap: 1rem;
        grid-template-columns: repeat(4,minmax(0,1fr));
    }
    .core-flash {
        width: 25%;
    }
}